export default {
  title: {
    en: 'Find your perfect <br class="desktopOnly"/>fragrance',
  },
  text: {
    en: `
      Our new AI feature compiles thousands of reviews <br/>
      into concise summaries for each scent. <br/>
      Make confident choices effortlessly.
    `,
  },
}
