export default {
  title: {
    en: `Here's how it works:`,
  },
  slides: [
    {
      title: {
        en: '1. Pick a scent',
      },
      text: {
        en: `Choose what you'd like to try from our best sellers — you'll get access to our full catalog after subscribing.`,
      },
    },
    {
      title: {
        en: '2. Activate your subscription',
      },
      text: {
        en: 'Once your subscription is active, the 8 mL bottle of your choosing will be sent directly to you.',
      },
    },
    {
      title: {
        en: '3. Receive your fragrance',
      },
      text: {
        en: 'Your first bottle will come dressed in a free atomizer case, and the 8 mL bottle holds roughly 120 sprays, which should last until your next order.',
      },
    },
  ],
}
