import React, { memo } from 'react'
import { useDevice } from 'device'
import { twcx } from 'helpers'

import { Image, Text } from 'components/dataDisplay'

import brandsDesktop from './images/brands-desktop.png?src-loader'
import brandsMobile from './images/brands-mobile.png?src-loader'
import brandsTransparentMobile from './images/brands-transparent-mobile.png?src-loader'

import messages from './messages'


type BrandsRowProps = {
  className?: string
  transparent?: boolean
}

const BrandsRow: React.FunctionComponent<BrandsRowProps> = (props) => {
  const { className, transparent = false } = props

  const { isMobile } = useDevice()

  const mobileImage = transparent ? brandsTransparentMobile : brandsMobile

  return (
    <>
      <Image
        className={twcx('mt-24 w-full', isMobile ? null : 'px-12', className)}
        src={isMobile ? mobileImage : brandsDesktop}
        alt="Bvlgari, Tocca, Kenneth Cole, Michael Kors, Marc Jacobs, Balenciaga"
        sizes={isMobile ? '100vw' : '1136px'}
      />
      <Text className="mt-24 text-center" message={messages.note} style={isMobile ? 'p3' : 'p1'} html />
    </>
  )
}

export default memo(BrandsRow)
