import React, { useCallback, useEffect, useRef } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import links from 'links'
import { track } from 'analytics'
import { openModal } from 'modals'

import { Logo, Text } from 'components/dataDisplay'
import { Button, buttonMessages } from 'components/inputs'
import { WidthContainer } from 'components/layout'

import SubscribeButton from 'compositions/buttons/SubscribeButton/SubscribeButton'
import GetStartedButton from 'pages/landing/View/components/GetStartedButton/GetStartedButton'
import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import messages from './messages'
import s from './ContinueStickyBar.module.css'


type StickyBarProps = {
  isVisible: boolean
  desktopText?: Intl.Message | string
  continueButtonMessage?: Intl.Message | string
  eventDiscountText: SubscriptionModule.EventDiscountText
  coupon: string
  customButton?: HomePageCustomBlockButton
  onContinue?: () => void
}

const ContinueStickyBar: React.FunctionComponent<StickyBarProps> = (props) => {
  const { isVisible, desktopText, continueButtonMessage, eventDiscountText, customButton, coupon, onContinue } = props

  const { isMobile } = useDevice()

  const isMountedRef = useRef(false)

  const handleContinueClick = useCallback(() => {
    track('Banner click', {
      placement: 'Sticky bar',
      content: 'Subscribe',
      action: 'link',
      link: links.subscribe,
      discount: eventDiscountText,
      coupon,
    })

    if (typeof onContinue === 'function') {
      onContinue()
    }
  }, [ eventDiscountText, coupon, onContinue ])

  const handleFaqClick = useCallback(() => {
    track('FAQ link click', {
      placement: 'Sticky bar',
    })
    openModal('faqModal')
  }, [])

  useEffect(() => {
    isMountedRef.current = true
  }, [ isVisible ])

  const rootClassName = cx(
    isMobile ? 'grid grid-cols-2' : 'bg-gold-30 py-24',
    s.root,
    isVisible && s.visible,
    isMountedRef.current ? '' : 'hidden',
    'fixed inset-x-0 top-0 z-floating-header'
  )

  let primaryButton: React.ReactNode

  if (customButton) {
    const ButtonComponent = customButton?.actionType === 'subscribe' ? SubscribeButton : GetStartedButton
    const buttonProps = {
      size: 56 as const,
      width: isMobile ? null : 240 as const,
      className: 'bg-deep-purple',
      ...customButton,
    }

    primaryButton = (
      <ButtonComponent
        {...buttonProps}
        placement="Brand block"
      />
    )
  }
  else {
    primaryButton = (
      <Button
        className="!bg-deep-purple"
        title={continueButtonMessage || buttonMessages.continue}
        inlineIcon="32/arrow-right"
        to={links.subscribe}
        size={56}
        width={isMobile ? null : 240}
        onClick={handleContinueClick}
        data-testid="subscribeButton"
      />
    )
  }

  if (isMobile) {
    return (
      <>
        <div className={rootClassName}>
          <Button
            title={messages.faq}
            size={56}
            style="gold-30"
            onClick={handleFaqClick}
            data-testid="faqButton"
          />
          {primaryButton}
        </div>
      </>
    )
  }

  return (
    <div className={rootClassName}>
      <WidthContainer className="flex items-center justify-end">
        {
          desktopText ? (
            <Text
              className="mr-auto"
              message={desktopText}
              style="p2"
              html
            />
          ) : (
            <Logo className="mr-auto" color="black" />
          )
        }
        <Button
          className="mr-16"
          title={messages.faq}
          size={56}
          width={240}
          style="secondary"
          onClick={handleFaqClick}
          data-testid="faqButton"
        />
        {primaryButton}
      </WidthContainer>
    </div>
  )
}


export default React.memo(ContinueStickyBar)
