import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Image, Text } from 'components/dataDisplay'
import { ScrollableContainer, WidthContainer } from 'components/layout'

import messages from './messages'

import image1 from './images/how-1.jpg?src-loader'
import image2 from './images/how-2.jpg?src-loader'
import image3 from './images/how-3.jpg?src-loader'
import imageR1 from './images/restyle/how-1.jpg?src-loader'
import imageR2 from './images/restyle/how-2.jpg?src-loader'
import imageR3 from './images/restyle/how-3.jpg?src-loader'


const images = [ image1, image2, image3 ]
const restyleImages = [ imageR1, imageR2, imageR3 ]

type Slide = {
  title: Intl.Message | string
  text: Intl.Message | string
  image: Image.StaticImageData
}

const getDefaultItems = (isRestyle: boolean): Slide[] => {
  return messages.slides.map(({ title, text }, index) => ({
    title,
    text,
    image: isRestyle ? restyleImages[index] : images[index],
  }))
}


export type HowItWorksBlockProps = {
  className?: string
  title?: Intl.Message
  items?: Slide[]
  restyleDefaultImages?: boolean
}

const HowItWorksBlock: React.CFC<HowItWorksBlockProps> = (props) => {
  const { children, className, title, items, restyleDefaultImages } = props

  const { isMobile } = useDevice()

  let finalItems = items || getDefaultItems(restyleDefaultImages)

  const slidesNode = finalItems.map(({ title, text, image }, index) => (
    <div key={image.src || index} className="snap-start scroll-ml-16 text-left">
      <Image
        className="object-cover object-center"
        src={image}
        alt=""
        remWidth={isMobile ? 356 : 368}
      />
      <Text
        className="mt-16"
        message={title}
        style="h6"
      />
      <Text
        className="mt-8"
        message={text}
        style="p2"
      />
    </div>
  ))

  return (
    <WidthContainer className={cx('text-center', className)} data-testid="howItWorksSection">
      <Text
        className="mb-32"
        message={title || messages.title}
        style={isMobile ? 'h5' : 'h3'}
      />
      {
        isMobile ? (
          <ScrollableContainer className="grid snap-x snap-mandatory auto-cols-[356rem] grid-flow-col gap-x-16">
            {slidesNode}
          </ScrollableContainer>
        ) : (
          <div className="grid grid-cols-3 gap-16">
            {slidesNode}
          </div>
        )
      }
      {children}
    </WidthContainer>
  )
}


export default React.memo(HowItWorksBlock)
