export default {
  title: {
    en: 'Shop best sellers',
  },
  perfumes: {
    en: 'Perfumes',
  },
  colognes: {
    en: 'Colognes',
  },
}
