import React, { memo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import { Image, Text } from 'components/dataDisplay'
import { AspectContainer, WidthContainer } from 'components/layout'

import AiSummaryNoteBlock from 'compositions/blocks/AiSummaryNoteBlock/AiSummaryNoteBlock'

import BrandsRow from '../BrandsRow/BrandsRow'

import heroDesktop from './images/hero-desktop.jpg?src-loader'
import heroMobile from './images/hero-mobile.jpg?src-loader'

import messages from './messages'


type AiSummaryBlockProps = {
  className?: string
  button?: React.ReactNode
}

const AiSummaryBlock: React.FunctionComponent<AiSummaryBlockProps> = (props) => {
  const { button, className } = props

  const { isMobile } = useDevice()

  if (isMobile) {
    return (
      <>
        <div className={cx(className, 'bg-light-beige')} data-testid="aiSummarySection">
          <AspectContainer aspect={heroMobile.aspect}>
            <Image
              src={heroMobile.src}
              sizes="100vw"
              fill
              alt="Bottles"
            />
          </AspectContainer>
          <div className="px-24 pt-24 text-center">
            <Text message={messages.title} style="h4" html />
            <Text className="mt-24" message={messages.text} style="p2" html />
            <AiSummaryNoteBlock className="mb-24 mt-36 px-24" />
            {button}
          </div>
        </div>
        <div className="mb-40 px-16">
          <BrandsRow transparent />
        </div>
      </>
    )
  }

  return (
    <WidthContainer className={className} data-testid="aiSummarySection">
      <div className="bg-light-beige flex items-center justify-between">
        <div className="max-w-[568rem] py-48 pl-64 pr-32">
          <Text message={messages.title} style="h3" html />
          <Text className="mt-24" message={messages.text} style="p2" html />
          <AiSummaryNoteBlock className="mt-[28rem]" />
          {button}
        </div>
        <AspectContainer className="w-6/12" aspect={heroDesktop.aspect}>
          <Image
            src={heroDesktop.src}
            alt="Bottles"
            fill
            sizes="568px"
          />
        </AspectContainer>
      </div>
      <BrandsRow className="mt-32" />
    </WidthContainer>
  )
}

export default memo(AiSummaryBlock)
