import React from 'react'

import { constants } from 'helpers'
import HeroBase, { type HeroBaseProps } from 'compositions/banners/HeroBase/HeroBase'

import messages from './messages'

import desktopImage from './images/bg-desktop.jpg'
import mobileImage from './images/bg-mobile.jpg'


type BottomBannerProps = {
  className?: string
  message?: Intl.Message
  button: HeroBaseProps['button']
}

const BottomBanner: React.FunctionComponent<BottomBannerProps> = ({ className, button, message }) => {
  return (
    <HeroBase
      className={className}
      title={message || { ...messages.title, values: { fragrancesAmount: constants.businessLogic.fragrancesAmount } }}
      desktopImage={desktopImage}
      mobileImage={mobileImage}
      button={button}
      desktopContentAtTop
      wideMT={false}
      wideMB
      data-testid="bottomBanner"
    />
  )
}


export default BottomBanner
