import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { track } from 'analytics'
import { useDevice } from 'device'
import { formatProductLabelsForAnalytics } from 'helpers'
import { useIntl } from 'intl'

import { normalizeRecommenderStrategies, useCnstrcRecommenderTrackingDataAttrs } from 'modules/constructorIO'
import { useBestsellersLikeToTry } from 'modules/recommendations'

import type { Money, UserGender } from 'typings/graphql'

import { useLandingProductsToAddToQueue } from 'containers/LandingContextProvider/LandingContextProvider'
import FetchMoreButtonProductList, { type FetchMoreButtonProductListProps } from 'compositions/productLists/FetchMoreButtonProductList/FetchMoreButtonProductList'

import messages from './messages'


const PLACEMENT = 'Bestsellers'

export type ProductListConstructorProps = {
  catalogueName?: ProductsModule.CatalogueName
  buttonProps: FetchMoreButtonProductListProps['buttonProps']
  isVisible: boolean
  limit?: number
  totalLimit?: number
  offset?: number
  fetchMoreButtonTitle?: FetchMoreButtonProductListProps['fetchMoreButtonTitle']
  fetchMoreButtonStyle?: FetchMoreButtonProductListProps['fetchMoreButtonStyle']
  withDetailedMobileCards?: boolean
  withoutLink?: boolean
  withRetailPrice?: boolean
}

const ProductListConstructor: React.FunctionComponent<ProductListConstructorProps> = (props) => {
  const {
    buttonProps,
    catalogueName,
    isVisible,
    limit,
    fetchMoreButtonTitle,
    fetchMoreButtonStyle,
    withDetailedMobileCards,
    withoutLink,
    withRetailPrice = false,
    totalLimit = 50,
  } = props

  const [ page, setPage ] = useState(0)

  const { isMobile } = useDevice()
  const intl = useIntl()

  const gender: UserGender = catalogueName === 'perfumes' ? 'FEMALE' : 'MALE'

  const productsLimit = limit || (isMobile ? 12 : 6)

  const { data, isFetching } = useBestsellersLikeToTry({
    gender,
    limit: totalLimit,
    skip: !isVisible,
  })

  const { pod, products = [], recommenderName } = data || {}

  useEffect(() => {
    if (!recommenderName) {
      return
    }

    track('Recommendations display', {
      placement: PLACEMENT,
      recommenderName,
    })
  }, [ recommenderName ])

  const dataAttributes = useCnstrcRecommenderTrackingDataAttrs({
    podId: pod?.id,
    resultId: pod?.resultId,
    resultsCount: products?.length || 0,
  })

  const recommenderStrategies = useMemo(() => {
    return normalizeRecommenderStrategies(data?.productsStrategies)
  }, [ data?.productsStrategies ])


  const handleProductLinkClick = (product: ProductFragment.Base, volume: string, price?: Money ) => {
    track('Recommendations product click', {
      isScentbirdSelect: product.theEdit,
      productBrand: product.brandInfo.name,
      productCategory: product.category,
      productFullName: product.fullName,
      productGender: product.gender,
      productId: product.id,
      productLabels: formatProductLabelsForAnalytics({ product, intl }),
      productPrice: (price?.amountCents || 0) / 100,
      productVolume: volume,
      recommenderName,
    })
  }

  const handleFetchMore = useCallback(() => {
    track('Show more products click', {
      placement: PLACEMENT,
    })

    setPage((page) => page + 1)
  }, [ setPage ])

  const promotedProducts = useLandingProductsToAddToQueue()
  const finalProducts = useMemo(() => {
    if (!data?.products || !promotedProducts?.isFetching && !promotedProducts?.products) {
      return null
    }

    return [].concat(promotedProducts?.products, data?.products).filter(Boolean)
  }, [ data?.products, promotedProducts ])

  const productsToShow = useMemo(() => {
    return finalProducts?.slice(0, (page + 1) * productsLimit)
  }, [ page, finalProducts, productsLimit ])

  const totalCount = Math.min(finalProducts?.length, totalLimit)

  return (
    <FetchMoreButtonProductList
      className="mt-24"
      products={productsToShow}
      totalCount={totalCount}
      isFetching={!isVisible || isFetching}
      fetchSkeletonsAmount={productsLimit}
      fetchMoreButtonTitle={fetchMoreButtonTitle || messages.buttonTitle}
      fetchMoreButtonStyle={fetchMoreButtonStyle || 'secondary'}
      buttonProps={buttonProps}
      fetchMore={handleFetchMore}
      withoutLink={withoutLink}
      withRetailPrice={withRetailPrice}
      withDetailedMobileCards={withDetailedMobileCards}
      excludeLabels={[ 'BESTSELLER', 'HOLIDAY_SALE', 'PLUS_FREE_GIFT' ]}
      dataAttributes={dataAttributes}
      recommenderStrategies={recommenderStrategies}
      onProductLinkClick={handleProductLinkClick}
    />
  )
}


export default ProductListConstructor
