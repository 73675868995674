import React from 'react'
import { useDevice } from 'device'
import { useEntry } from 'intersection-observer'
import { usePathname } from 'router'

import { useCnstrcRecommendationsEnabled } from 'modules/constructorIO'
import { useUser } from 'modules/user'

import { Text } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'
import { Tabs } from 'components/navigation'

import ProductList, { type ProductListProps } from './components/ProductList/ProductList'
import ProductListConstructor from './components/ProductListConstructor/ProductListConstructor'

import messages from './messages'


const tabIds = {
  perfumes: 'perfumes',
  colognes: 'colognes',
}

type BestsellersSectionProps = {
  id?: string
  className?: string
  limit?: number
  totalLimit?: number
  offset?: number
  title?: Intl.Message
  fetchMoreButtonTitle?: Intl.Message
  withoutLink?: boolean
  withRetailPrice?: boolean
  buttonProps: ProductListProps['buttonProps']
  fetchMoreButtonStyle?: ProductListProps['fetchMoreButtonStyle']
  ignoreConstructorRecommendationsAbTestForLeads?: boolean
}

const BestsellersBlock: React.FunctionComponent<BestsellersSectionProps> = (props) => {
  const {
    id,
    className,
    title,
    limit,
    totalLimit,
    offset,
    buttonProps,
    fetchMoreButtonTitle,
    withoutLink = true,
    withRetailPrice,
    fetchMoreButtonStyle,
    ignoreConstructorRecommendationsAbTestForLeads = false,
  } = props

  const { isMobile, isBot } = useDevice()
  const pathname = usePathname()
  const { gender, isLoggedIn } = useUser()

  const areConstructorRecommendationsEnabled = useCnstrcRecommendationsEnabled({ ignoreAbTestForLeads: ignoreConstructorRecommendationsAbTestForLeads })

  const [ ref, entry ] = useEntry({ once: true, observerProps: { rootMargin: '25%' } })

  const isSectionVisible = isBot || entry?.isIntersecting

  const ProductListComponent = areConstructorRecommendationsEnabled ? ProductListConstructor : ProductList

  // don't make strict assertion for non-authorized users because of different urls
  const isMale = isLoggedIn ? gender === 'MALE' : /\/men/.test(pathname)
  const defaultTab = isMale ? tabIds.colognes : tabIds.perfumes

  let tabs = [
    <Tabs.Content key="perfumes" id={tabIds.perfumes} title={messages.perfumes}>
      <ProductListComponent
        limit={limit}
        totalLimit={totalLimit}
        offset={offset}
        catalogueName="perfumes"
        buttonProps={buttonProps}
        isVisible={isSectionVisible}
        withoutLink={withoutLink}
        withRetailPrice={withRetailPrice}
        fetchMoreButtonTitle={fetchMoreButtonTitle}
        fetchMoreButtonStyle={fetchMoreButtonStyle}
      />
    </Tabs.Content>,
    <Tabs.Content key="colognes" id={tabIds.colognes} title={messages.colognes}>
      <ProductListComponent
        limit={limit}
        totalLimit={totalLimit}
        offset={offset}
        catalogueName="colognes"
        buttonProps={buttonProps}
        isVisible={isSectionVisible}
        withoutLink={withoutLink}
        withRetailPrice={withRetailPrice}
        fetchMoreButtonTitle={fetchMoreButtonTitle}
        fetchMoreButtonStyle={fetchMoreButtonStyle}
      />
    </Tabs.Content>,
  ]

  if (isMale) {
    tabs.reverse()
  }

  return (
    <WidthContainer ref={ref} id={id} className={className}>
      <Text message={title || messages.title} style={isMobile ? 'h4' : 'h3'} html />
      <Tabs className={isMobile ? 'mt-32' : 'mt-40'} defaultActiveTab={defaultTab}>
        {tabs}
      </Tabs>
    </WidthContainer>
  )
}


export default React.memo(BestsellersBlock)
